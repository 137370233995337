// 填报详情
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <v-row class="mt-5">
      <v-col
        md="8"
        sm="12"
      >
        <v-card class="pa-7 mt-0">
          <div class="project_operation">
            <v-btn
              v-if="orgQX"
              text
              small
              color="red darken-1"
              @click="deleteMobelBtn2(dynamicList,'中止')"
            >
              中止
            </v-btn>
            <v-btn
              text
              small
              color="blue darken-1"
              @click="see"
            >
              项目详情
            </v-btn>
          </div>
          <h1 class="text-center red--text text--darken-4 projectName mb-5">
            {{ projectName }}
          </h1>
          <v-row v-if="false">
            <v-col md="12">
              <v-stepper :value="stageValue(stage)">
                <v-stepper-header>
                  <v-stepper-step step="1">
                    接收
                  </v-stepper-step>
                  <v-divider />
                  <v-stepper-step step="2">
                    在谈
                  </v-stepper-step>
                  <v-divider />
                  <v-stepper-step step="3">
                    签约
                  </v-stepper-step>
                  <v-divider />
                  <v-stepper-step step="4">
                    落地
                  </v-stepper-step>
                  <v-divider />
                </v-stepper-header>
              </v-stepper>
            </v-col>
          </v-row>
          <v-row v-if="orgQX">
            <template v-for="projectInfo in newProjectInfo">
              <v-col
                v-if="projectInfo.value && projectInfo.code !== 'tags' && projectInfo.type !== 'files' && projectInfo.code !== 'name'"
                :key="projectInfo.code"
                cols="12"
              >
                <b class="red--text text--darken-4">
                  {{ projectInfo.name }}：
                </b>
                <span>
                  {{ projectInfo.value }}
                </span>
              </v-col>
              <v-col
                v-if="projectInfo.code === 'tags' && projectInfo.value"
                :key="projectInfo.code"
              >
                <tags :tags-data="projectInfo" />
              </v-col>
              <v-col
                v-if="projectInfo.type === 'files' && projectInfo.value"
                :key="projectInfo.code"
              >
                <files :file="projectInfo" />
              </v-col>
            </template>
          </v-row>
          <v-container
            v-if="!orgQX"
            class="pl-0 pr-0"
          >
            <div class="line_box">
              <v-tabs
                dark
                background-color="#fff"
                show-arrows
                class="mb-4"
              >
                <v-tabs-slider color="#0D47A1" />

                <template v-for="(item,inx) in userGroupTree">
                  <v-tab
                    v-show="item.totalElements"
                    :key="inx"
                    style="color:#0D47A1"
                    @click="getParkList(item.id)"
                  >
                    <v-badge
                      color="#0D47A1"
                      :value="item.totalElements?true:false"
                      :content="item.totalElements"
                    >
                      {{ item.name }}
                    </v-badge>
                  </v-tab>
                </template>
              </v-tabs>

              <v-row
                v-for="(item, inx) in projectList"
                :key="inx"
                class="mt-4"
              >
                <v-col cols="6">
                  <v-chip
                    class="white--text ml-0"
                    :color="runColor(item.data.stageStatus)"
                    label
                    small
                  >
                    {{ item.data.stageStatus }}
                  </v-chip>
                  <span class="ml-1">
                    {{ item.data.park }}</span>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right "
                >
                  {{ item.data.createdAt | dateformat1 }}

                  <v-menu
                    v-if="item.data.stageStatus ==='在谈' || item.data.stageStatus ==='签约' || item.data.stageStatus ==='落地'"
                    offset-y
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-avatar
                        size="36"
                        v-on="on"
                      >
                        <v-icon style="cursor:pointer">
                          mdi-dots-vertical
                        </v-icon>
                      </v-avatar>
                    </template>
                    <v-list>
                      <v-list-item
                        style="cursor:pointer"
                        @click="edit(item)"
                      >
                        <v-icon
                          color="#2196f3"
                          small
                          class="mr-1"
                        >
                          mdi-pencil
                        </v-icon>
                        <span style="color:#2196f3">编辑</span>
                      </v-list-item>
                      <v-list-item
                        style="cursor:pointer"
                        @click="deleteMobelBtn(item)"
                      >
                        <v-icon
                          color="#f44336"
                          small
                          class="mr-2"
                        >
                          fa4 fa-trash-o
                        </v-icon>
                        <span style="color:#f44336">删除</span>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-card
                    class="mx-auto pa-5 mt-0"
                    outlined
                  >
                    <p class="ma-0">
                      {{ item.data.content }}
                    </p>
                  </v-card>
                </v-col>
              </v-row>

              <v-pagination
                v-model="nowPage"
                :length="pageNum"
                total-visible="10"
                color="indigo darken-4"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                @input="pageChange"
              />
            </div>
          </v-container>
        </v-card>

        <planmove
          v-if="orgQX && isCjTs"
          ref="planmove"
          :report-id="id"
          :form-id="formId"
        />
        <advancemove
          v-if="orgQX && isCjTs"
          ref="advancemove"
          :report-id="id"
          :form-id="formId"
        />
      </v-col>
      <v-col
        md="4"
        sm="12"
      >
        <waiter
          :user-id="userId"
          :report-id="id"
          :form-id="formId"
        />
        <v-card
          v-if="false"
          class="pa-2"
        >
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-select
              v-model="stageStatus"
              clearable
              :items="stageStatuss"
              label="对接阶段"
              item-text="name"
              item-value="code"
              solo
              dense
            />
          </v-col>
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-textarea
              v-model="content"
              solo
              name="input-7-4"
              label="项目动态描述"
            />
          </v-col>
          <v-col
            cols="12"
            class="pa-0 d-flex justify-end"
          >
            <v-btn
              color="red"
              @click="stageStatus = '',content=''"
            >
              清空
            </v-btn>
            <v-btn
              class="ml-5"
              color="green"
              @click="release"
            >
              发布动态
            </v-btn>
          </v-col>
          <v-row
            v-for="(item, inx) in projectList"
            :key="inx"
            class="mt-4"
          >
            <v-col cols="6">
              <v-chip
                class="white--text ml-0"
                :color="runColor(item.data.stageStatus)"
                label
                small
              >
                {{ item.data.stageStatus }}
              </v-chip>
              <span class="ml-1">
                {{ item.data.park }}</span>
            </v-col>
            <v-col
              cols="6"
              class="text-right "
            >
              {{ item.data.createdAt | dateformat1 }}

              <v-menu
                v-if="item.data.stageStatus ==='在谈' || item.data.stageStatus ==='签约' || item.data.stageStatus ==='落地'"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-avatar
                    size="36"
                    v-on="on"
                  >
                    <v-icon style="cursor:pointer">
                      mdi-dots-vertical
                    </v-icon>
                  </v-avatar>
                </template>
                <v-list>
                  <v-list-item
                    style="cursor:pointer"
                    @click="edit(item)"
                  >
                    <v-icon
                      color="#2196f3"
                      small
                      class="mr-1"
                    >
                      mdi-pencil
                    </v-icon>
                    <span style="color:#2196f3">编辑</span>
                  </v-list-item>
                  <v-list-item
                    style="cursor:pointer"
                    @click="deleteMobelBtn(item)"
                  >
                    <v-icon
                      color="#f44336"
                      small
                      class="mr-2"
                    >
                      fa4 fa-trash-o
                    </v-icon>
                    <span style="color:#f44336">删除</span>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-card
                class="mx-auto pa-5 mt-0"
                outlined
              >
                <p class="ma-0">
                  {{ item.data.content }}
                </p>
              </v-card>
            </v-col>
          </v-row>
          <v-pagination
            v-model="nowPage"
            :length="pageNum"
            total-visible="10"
            color="indigo darken-4"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            @input="pageChange"
          />
        </v-card>
        <log :report-id="id" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="userSelect"
      max-width="1000px"
      style="overflow-y:none"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>编辑</v-toolbar-title>
          <v-spacer />
          <v-btn
            color="#0D47A1"
            @click="savemove"
          >
            保存
          </v-btn>
        </v-toolbar>
        <div class="pa-6">
          <v-col
            cols="2"
            class="pa-0"
          >
            <v-select
              v-model="stageStatus"
              clearable
              :items="stageStatuss"
              label="对接阶段"
              item-text="name"
              item-value="code"
              solo
              dense
            />
          </v-col>
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-textarea
              v-model="content"
              solo
              name="input-7-4"
              label="项目动态描述"
            />
          </v-col>
        </div>
      </v-card>
    </v-dialog>
    <audioBox ref="audioBox" />
  </v-container>
</template>
<script>
  import details from '../../utils/details'
  export default {
    components: {
      waiter: () => import('./component/Waiter'),
      log: () => import('./component/Logs'),
      audioBox: () => import('../../components/base/Audio'),
      planmove: () => import('./component/Planmove'),
      Advancemove: () => import('./component/Advancemove'),
    },
    data: () => ({
      stage: '',
      userSelect: false,
      nowPage: 1,
      pageNum: 0,
      id: '',
      projectName: '',
      userId: '',
      stageStatuss: [],
      stageStatus: '',
      content: '',
      childformId: 'cf45fb6b-8c8f-4e1e-b9f8-1b2b774fea2b',
      detailList: {},
      dynamicList: {},
      searchParame: {
        page: 0,
        size: 5,
        formId: 'cf45fb6b-8c8f-4e1e-b9f8-1b2b774fea2b',
        // _from: 'grant',
      },
      formId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
      projectList: [],
      projectStatistics: 0,
      projectTotal: 0,
      userGroupTree: [],
      itemData: [],
      allParkIds: '',
      orgQX: false,
      newProjectInfo: [],
      // 外商
      wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
      isCjTs: false,
    }),
    created () {
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
      this.id = this.$route.params.id
      if (this.$route.query.dynamicId) {
        this.searchParame.dynamicId = this.$route.query.dynamicId
      }
    },
    async mounted () {
      this.MergeData()
      this.stageStatuss = await this.getdictionar('825f1c35-4a15-493f-9655-eb4608a48e28')
      this.getUserGroupTree()
      this.getProjectInfo2()
      this.getProjectList()
    },
    methods: {
      //! 合并数据
      async MergeData () {
        // 获取项目信息
        const projectInfoObj = await this.getProjectInfo()
        console.log('projectInfoObj', projectInfoObj);

        // 获取表单数据
        const dataListObj = await this.getDataList()
        // 合并和兑换数据
        this.newProjectInfo = await details.detailsData(dataListObj, projectInfoObj)
        console.log('this.newProjectInfo', this.newProjectInfo);
      },
      async getDataList () {
        return this.$axios.get('/forms/' + this.$route.params.formId + '/fields')
      },
      async getProjectInfo () {
        const resData = await this.$axios.get('/reports/' + this.id + '/detail')
        if (this.orgQX && this.$route.params.formId === this.wsFormId) {
          delete resData.data.data.data.inferred
          delete resData.data.data.data.kfqHandle
          delete resData.data.data.data.tzcHandle
          delete resData.data.data.data.tzcfzrHandle
          delete resData.data.data.data.isPush1
          delete resData.data.data.data.pushReason
        }
        return resData.data.data
      },
      stageValue (stage) {
        if (!stage) {
          return 0
        } else if (stage === '接收') {
          return 1
        } else if (stage === '在谈') {
          return 2
        } else if (stage === '签约') {
          return 3
        } else if (stage === '落地') {
          return 4
        }
      },
      deleteMobelBtn2 (item, name) {
        this.$swal({
          title: `你确定${name}对接此项目吗？`,
          // text: '中止对接后将无法再次接受对接',
          input: 'textarea',
          animation: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showLoaderOnConfirm: true,
          inputPlaceholder: `请填写${name}对接理由`,
          preConfirm: (content) => {
            if (content === '') {
              this.$refs.audioBox.audioPlay('error')
              this.$swal.showValidationMessage(
                `${name}对接理由不能为空！`,
              )
            } else {
              this.dockint2(item, name, content)
            }
          },
        })
      },
      async dockint2 (item, name, content) {
        const answerTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        const searchParames = {
          abortReason: content,
          answerTime: answerTime,
          stageStatus: name,
        }
        // 判断是否对接/接收
        if (name === '对接' || name === '接收') {
          searchParames.isDocking = '是'
        } else {
          searchParames.isDocking = '否'
        }
        const resp = await this.$axios.patch(`/reports/${item.id}`,
                                             searchParames,
        )
        console.log('resp', resp);
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '未知错误!',
            icon: 'error',
          })
        }
        // 创建推进下的动态(下面授权)
        this.release2(item, item.data.parkId, name, content)
        this.getProjectList()
        this.$swal({
          title: `已${name}`,
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
      },
      async release2 (item, groupId, name, content) {
        const filterFormData2 = {}
        filterFormData2.dynamicId = item.id
        filterFormData2.parentName = item.data.parentName
        filterFormData2.parentID = item.data.parentID
        filterFormData2.content = content
        filterFormData2.stageStatus = name
        filterFormData2.name = item.data.parentName + `--${name}`
        filterFormData2.park = item.data.park
        filterFormData2.parkId = item.data.parkId
        filterFormData2.parkIds = item.data.parkIds
        const resp = await this.$axios.post(`/forms/${this.childformId}/reports3`, {
          data: filterFormData2,
          status: 0,
          weight: 10000,
          // 推送表id
          parentId: item.id,
        })
        console.log('resp', resp);
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '未知错误!',
            icon: 'error',
          })
        }
        this.putGroup(resp.data.data.id, groupId)
      },
      // 授权给组织机构
      async putGroup (reportId, groupIds) {
        const res = await this.$axios.post(`/reports/${reportId}/access`, { grantId: groupIds, type: 'role', permission: 'read,write' })
        if (res.data.code !== 200) {
          return this.$swal({
            title: '授权失败!',
            icon: 'error',
          })
        }
        // 项目园区信息
        await this.getProjectInfo2()
        // 动态
        await this.getProjectList()
        console.log(res)
      },
      see () { // 查看项目
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: this.$route.params.formId,
            id: this.$route.params.id,
          },
        })
      },
      getParkList (allParkIds) {
        this.allParkIds = allParkIds
        this.searchParame.page = 0
        this.nowPage = 1
        this.getProjectList()
      },
      edit (item) {
        this.itemData = item
        this.stageStatus = this.itemData.data.stageStatus
        this.content = this.itemData.data.content
        this.userSelect = true
      },
      async savemove () {
        console.log('this.itemData', this.itemData);
        const resp = await this.$axios.patch(`/reports/${this.itemData.id}`, {
          stageStatus: this.stageStatus,
          content: this.content,
        })
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '编辑失败!',
            icon: 'error',
          })
        }
        this.stageStatus = ''
        this.content = ''
        this.userSelect = false
        this.getUserGroupTree()
        this.getProjectList()
        this.$swal({
          title: '编辑成功！',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
      },
      deleteMobelBtn (item) { // 删除按钮弹出弹框
        this.$swal({
          title: '你确定要删除此动态吗？',
          text: '动态删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.deleteProject(item)
          }
        })
      },
      deleteProject (item) { // 删除项目
        this.$axios.delete('/reports/' + item.id)
          .then((res) => {
            this.projectList.splice(this.projectList.indexOf(item), 1)
            this.$swal({
              title: '删除成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((error) => {
            console.log(error)
            this.$swal({
              title: '删除失败!',
              icon: 'error',
            })
          })
      },
      async getUserGroupTree () {
        const res = await this.$axios.get('/dictionaries/roles')
        const resData = res.data.data
        resData.map(val => {
          // 推送园区为父级
          if (val.value === 'ORG_TUISONGYUANQU') {
            const userGroupTree = this.treeData(resData, val.id)
            userGroupTree.sort((a, b) => {
              return a.weight - b.weight
            })
            userGroupTree.unshift({ name: '全部', id: '' })
            userGroupTree.map(async val => {
              this.$set(val, 'totalElements', await this.getTotalList(val.id))
            })
            this.userGroupTree = userGroupTree
            console.log('userGroupTree', this.userGroupTree);
          }
        })
      },
      treeData (sourceData, id) {
        const cloneData = JSON.parse(JSON.stringify(sourceData))
        return cloneData.filter(father => {
          return father.parentId + '' === id + ''
        })
      },
      runColor (key) {
        let color = '#ACB4C7'
        switch (key) {
          case '在谈':
            color = '#20c954'
            break;
          case '落地':
            color = '#01579B'
            break;
          case '签约':
            color = '#FF9900'
            break;
          case '中止':
            color = 'red'
            break;
          case '对接':
            color = '#20c954'
            break;
          default:
            break;
        }
        return color
      },
      async getTotalList (parkIds) { // 获取项目列表
        const searchParame = JSON.parse(JSON.stringify(this.searchParame))
        searchParame.parkIds = parkIds
        searchParame.parentID = this.id
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        })

        const resData = resp.data.data
        //  console.log('resData.totalElements', resData, resData.totalElements);

        return resData.totalElements
      },
      async getProjectList () { // 获取项目列表
        const searchParame = JSON.parse(JSON.stringify(this.searchParame))
        if (this.allParkIds) {
          searchParame.parkIds = this.allParkIds
        } else {
          delete searchParame.parkIds
        }
        if (!this.orgQX) {
          searchParame._from = 'public'
        }
        searchParame.parentID = this.id
        const Timeout = setTimeout(() => { this.overlay = true }, 200)
        await this.$axios.get('/reports/query', {
          params: searchParame, // 搜索参数对象
        })
          .then((response) => {
            const resData = response.data.data
            const projectList = JSON.parse(JSON.stringify(resData.content))
            this.projectList = projectList
            const cjtsLiat = projectList[0]
            // 待处理，中止，拒绝不显示承接方案 项目推进情况
            if (this.orgQX && cjtsLiat) {
              if (cjtsLiat.data.stageStatus === '中止' || cjtsLiat.data.stageStatus === '拒绝' || cjtsLiat.data.stageStatus === '待处理') {
                this.isCjTs = false
              } else {
                this.isCjTs = true
              }
            }

            this.projectStatistics = resData.totalElements // 项目总数统计数
            this.pageNum = resData.totalPages
            this.projectTotal = resData.totalElements
            if (!resData.totalPages > 0) {
              this.$swal({
                title: '未搜索到数据!',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
              })
            }
            clearTimeout(Timeout)
            this.overlay = false
          })
          .catch((error) => {
            console.log(error)
            this.overlay = false
          })
      },
      async getdictionar (name) {
        const resp = await this.$axios.get(`/dictionaries/${name}`)
        return resp.data.data
      },
      async getProjectInfo2 () {
        //! 获取项目信息
        const resData = await this.$axios.get('/reports/' + this.id + '/detail')
        // 获取项目名称
        this.projectName = resData.data.data.name
        this.userId = resData.data.data.userId
        this.detailList = resData.data.data
        //! 获取园区信息
        const resp = await this.$axios.get('/reports/' + this.$route.query.dynamicId + '/detail')
        console.log('respList', resp);
        this.dynamicList = resp.data.data
        this.stage = resp.data.data.data.stageStatus
      },
      async dockint () {
        const answerTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        const resp = await this.$axios.patch(`/reports/${this.$route.query.dynamicId}`, {
          content: this.content,
          stageStatus: this.stageStatus,
          answerTime: answerTime,
        })
        console.log('resp', resp);
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '未知错误!',
            icon: 'error',
          })
        }
      },
      async release () {
        if (!this.content || !this.stageStatus) {
          return this.$swal({
            title: '请填写完整再次提交!',
            icon: 'error',
          })
        }
        const filterFormData = {}
        // 推送id
        filterFormData.dynamicId = this.$route.query.dynamicId
        filterFormData.parentName = this.detailList.name
        // 项目id
        filterFormData.parentID = this.detailList.id
        filterFormData.content = this.content
        filterFormData.stageStatus = this.stageStatus
        filterFormData.park = this.$route.query.park
        filterFormData.parkId = this.$route.query.parkId
        filterFormData.parkIds = this.$route.query.parkIds
        filterFormData.name = this.detailList.name + '--' + this.stageStatus
        const resp = await this.$axios.post(`/forms/${this.childformId}/reports3`, {
          data: filterFormData,
          status: 0,
          weight: 10000,
          // 推送表id
          parentId: this.$route.query.dynamicId,
        })
        console.log('resp', resp);
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '未知错误!',
            icon: 'error',
          })
        }
        // 更新推进阶段
        await this.dockint()
        // 项目园区信息
        await this.getProjectInfo2()
        this.getUserGroupTree()
        this.getProjectList()
        this.$swal({
          title: '发布成功！',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
        this.content = ''
        this.stageStatus = ''
      },
      pageChange (value) { // 换页按钮
        this.nowPage = value
        this.searchParame.page = value - 1
        this.getProjectList()
      },
    },
  }
</script>
<style scoped>
@import url('./styles/reportDetails.scss');
::v-deep .fa-chevron-right {
  color: #0d47a1;
}
::v-deep .fa-chevron-left {
  color: #0d47a1;
}
</style>
