import http from '../api';
class Dictionary {
  async codeToName (dicId, codes) {
    const name = await http.dictionary.getDictionaryName(dicId, codes);
    if (name.data.data.value) {
      return name.data.data.value;
    } else {
      return codes;
    }
  }

  async specialCodeToName (dicName, codes) {
    const dicArr = [];
    const dicData = await (
      await http.dictionary.getDictionary(dicName)
    ).data.data;
    for (const val of codes) {
      // const dicName = ;
      for (const data of dicData) {
        if (val === data.code) {
          dicArr.push(data.name);
        }
      }
    }
    return dicArr;
  }
}
export default new Dictionary();
